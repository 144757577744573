import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Traveller } from "../requirements/models";
import { useEffect, useState } from "react";
import { PNR } from "websdk-businessobjects";
import { MarineCode } from "../requirements/utils";
import { createOrUpdateMarineRm } from "../requirements/services";

interface FlightRequirementsFormProps {
  traveller: Traveller;
  pnr: PNR;
  fetchData: () => Promise<void>;
}

const EditErmInfo: React.FC<FlightRequirementsFormProps> = ({
  traveller,
  fetchData,
  pnr,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveMessage, setSaveMessage] = useState<string | null>(null);
  const [ermName, setErmName] = useState<string>(
    traveller.energyMarineInfo?.ermName ?? ""
  );
  const [regCountryCode, setRegCountryCode] = useState<string>(
    traveller.energyMarineInfo?.regCountryCode ?? ""
  );
  const [marineCode, setMarineCode] = useState<MarineCode | null>(
    traveller.energyMarineInfo?.marineCode ?? null
  );

  useEffect(() => {
    setErmName(traveller.energyMarineInfo?.ermName ?? "");
    setRegCountryCode(traveller.energyMarineInfo?.regCountryCode ?? "");
  }, [traveller]);

  const toMarineCode = (marineCode: string) => {
    if (marineCode === "VSL") return MarineCode.VSL;
    if (marineCode === "RIG") return MarineCode.RIG;
    if (marineCode === "WMF") return MarineCode.WMF;
    throw new Error("Invalid marine code");
  };

  const save = async () => {
    setIsLoading(true);
    await createOrUpdateMarineRm(pnr, traveller.tatooNumber, {
      ermName: ermName,
      regCountryCode: regCountryCode,
      marineCode: marineCode!,
    });
    await fetchData();
    setIsLoading(false);
    setSaveMessage("Changes saved successfully.");
  };

  return (
    <Box display="flex" flexDirection="column" mb={1.5}>
      <Typography>{traveller.fullName}</Typography>
      <Stack spacing={2}>
        <FormControl fullWidth>
          <InputLabel
            id="vessel-type-label"
            sx={{ textAlign: "left" }}
            required
          >
            Type
          </InputLabel>
          <Select
            labelId="vessel-type-label"
            value={marineCode?.toString()}
            onChange={(e) => setMarineCode(toMarineCode(e.target.value))}
            label="Type"
            sx={{ display: "flex", flexDirection: "row", alignItems: "left" }}
          >
            {Object.keys(MarineCode)
              .filter((k) => Number.isNaN(Number(k)))
              .map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                  }}
                >
                  {type}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Name"
          value={ermName}
          onChange={(e) => setErmName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Nationality"
          value={regCountryCode}
          onChange={(e) => setRegCountryCode(e.target.value)}
        ></TextField>

        <LoadingButton
          onClick={() => save()}
          variant="contained"
          sx={{ mt: 1, alignSelf: "flex-start" }}
          loading={isLoading}
        >
          Validate and Save
        </LoadingButton>
      </Stack>

      {saveMessage && (
        <Typography
          variant="body2"
          color={saveMessage.includes("success") ? "primary" : "error"}
          sx={{ mt: 1 }}
        >
          {saveMessage}
        </Typography>
      )}
    </Box>
  );
};

export default EditErmInfo;
