import Collapsible from "../requirements/Collapsible";
import { TST } from "websdk-businessobjects";
import { Traveller, Ticket } from "../requirements/models";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

interface CheckLowerPriceProps {
  tst: TST;
  travellers: Traveller[];
  fetchData: () => Promise<void>;
}

const CheckLowerPrice = ({
  tst,
  travellers,
  fetchData,
}: CheckLowerPriceProps) => {
  return (
    <Collapsible title="Pricing" isDefaultExpanded={false}>
      {travellers.map((traveller) => (
        <Collapsible
          key={traveller.tatooNumber}
          title={traveller.fullName}
          isDefaultExpanded={false}
        >
          <Button>Check lowest price</Button>
          <Button>Check agreement price</Button>
          <Button>Check savings good concience</Button>
          <Button>Check savings</Button>

          <Collapsible title="Tickets" isDefaultExpanded={false}>
            {traveller.tickets && traveller.tickets.length > 0 ? (
              <List>
                <Typography variant="body1" textAlign={"left"}>
                  {traveller.tickets.map((ticket: Ticket, index: number) => (
                    <Box key={index} mb={2} p={2} border={1} borderRadius={4}>
                      <Typography variant="h6">Ticket {index + 1}</Typography>
                      <Typography>Fare Type: {ticket.fareType}</Typography>
                      <Typography>Currency: {ticket.currency}</Typography>
                      <Typography>Amount: {ticket.amount}</Typography>
                      <Typography>
                        Agreement Code:{" "}
                        {ticket.agreementCode === "" || !!ticket.agreementCode
                          ? "None"
                          : ticket.agreementCode}
                      </Typography>
                      <Typography>
                        Associated with Marine:{" "}
                        {ticket.isAssociatedWithMarine ? "Yes" : "No"}
                      </Typography>
                      <TextField label="Agreement Code"></TextField>
                      <Button>Save</Button>
                    </Box>
                  ))}
                </Typography>
              </List>
            ) : (
              <p>No tickets available</p>
            )}
          </Collapsible>
        </Collapsible>
      ))}
    </Collapsible>
  );
};

export default CheckLowerPrice;
