import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RequirementDetails, Traveller } from "./models";
import { PNR } from "websdk-businessobjects";
import { useEffect, useState } from "react";
import {  updateRequirements, validateRequirements } from "./services";
import Collapsible from "./Collapsible";

interface RequirementsFormProps {
  traveller: Traveller;
  fetchData: () => Promise<void>;
  pnr: PNR;
}

const RequirementsForm: React.FC<RequirementsFormProps> = ({
  traveller,
  fetchData,
  pnr,
}) => {
  const [requirements, setRequirements] = useState<RequirementDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveMessage, setSaveMessage] = useState<string | null>(null);

  useEffect(() => {
    setRequirements([...traveller.requirements]);
  }, [traveller.requirements]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRequirements((prevRequirements) =>
      prevRequirements.map((r) => (r.name === name ? { ...r, value } : r))
    );
  };

  const save = async (updatedTraveller: Traveller) => {
    await updateRequirements(pnr, updatedTraveller);
    await fetchData();
    setSaveMessage("Changes saved successfully.");
  };

  const handleValidateAndSave = async () => {
    setIsLoading(true);
    setSaveMessage(null);
    try {
      const withValidationStatus = await validateRequirements(
        traveller.lacNo,
        requirements
      );
      setRequirements(withValidationStatus);

      if (withValidationStatus.every((e) => e.isValid)) {
        await save({ ...traveller, requirements: withValidationStatus });
      } else {
        setSaveMessage("Please correct the errors before saving.");
      }
    } catch (error) {
      console.error("Validation failed", error);
      setSaveMessage("An error occurred during validation.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" mb={1.5}>
      <Collapsible
        title={traveller.fullName}
        isDefaultExpanded={!requirements.every((r) => r.isValid)}
      >
        {requirements.length > 0
          ? requirements.map(({ name, value, isValid, errorMessage }) => (
              <TextField
                sx={{ mb: 0.7 }}
                key={name}
                fullWidth
                label={name}
                name={name}
                value={value}
                onChange={handleChange}
                required
                error={!isValid}
                helperText={errorMessage}
              />
            ))
          : `No requirements for ${traveller.fullName}`}
      </Collapsible>
      <LoadingButton
        onClick={handleValidateAndSave}
        variant="contained"
        sx={{ mt: 1, alignSelf: "flex-start" }}
        loading={isLoading}
      >
        Validate and save
      </LoadingButton>
      {saveMessage && (
        <Typography
          variant="body2"
          color={saveMessage.includes("success") ? "primary" : "error"}
          sx={{ mt: 1 }}
        >
          {saveMessage}
        </Typography>
      )}
    </Box>
  );
};

export default RequirementsForm;
