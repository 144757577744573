import {
  ERMInfo,
  GetLacResponse,
  RequirementDetails,
  SegmentType,
  StatusCode,
  Traveller,
} from "./models";
import lacTestData from "./lacTestData.json";
import {
  AddCancelInput,
  AddRemarkInput,
  PNR,
  PNRService,
  RMElement,
} from "websdk-businessobjects";
import {
  findMarineRm,
  RequirementName,
  toMarineRemark,
  toRequirementCode,
} from "./utils";
import { firstValueFrom } from "rxjs";

export const updateRequirements = async (pnr: PNR, traveller: Traveller) => {
  const existingRemarks = existingRequirementRemarks(
    pnr,
    traveller.tatooNumber
  );
  if (existingRemarks.length > 0) {
    pnr.addCancel(existingRemarks);
    await firstValueFrom(PNRService.sendCancelElements(pnr));
  }

  pnr.addRemark([...requirementRemarks(traveller)]);
  await firstValueFrom(PNRService.sendAddElements(pnr));
};

export const fetchLac = async (lacNo: number) => {
  if (process.env.REACT_APP_ENV === "local")
    return Promise.resolve(lacTestData);

  const response = await fetch(
    `${process.env.REACT_APP_M3_URL}lac?LacNo=${lacNo}`
  );
  return await (response.json() as Promise<GetLacResponse>);
};

export const validateRequirement = async (
  requirementDetails: RequirementDetails,
  lacNo: number
): Promise<RequirementDetails> => {
  return fetch(
    `${process.env.REACT_APP_M3_URL}validatectrl-${requirementDetails.name}?LacNo=${lacNo}&Param=${requirementDetails.value}`
  )
    .then((res) => res.json())
    .then((res) => {
      return {
        ...requirementDetails,
        isValid: res.Status.Code === StatusCode.Success,
        errorMessage: res.Status.Message,
      };
    });
};

export const validateRequirements = async (
  lacNo: number,
  requirements: RequirementDetails[]
): Promise<RequirementDetails[]> => {
  const validationTasks = requirements.map((req) =>
    validateRequirement(req, lacNo)
  );
  return await Promise.all(validationTasks);
};

const existingRequirementRemarks = (
  pnr: PNR,
  travellerNo: number
): AddCancelInput[] => {
  const requirementCodes = [
    RequirementName.CustRef,
    RequirementName.DepCode,
    RequirementName.ProCode,
    RequirementName.ProSubCode,
    RequirementName.TravCode,
  ].map(toRequirementCode);

  return pnr.rmElements
    .filter((rmElement) =>
      requirementCodes.some(
        (code) =>
          rmElement.freeFlowText.startsWith(code) &&
          rmElement.associations.some(
            (a) => a.tatooNumber === travellerNo.toString()
          )
      )
    )
    .map(({ segmentType, tatooNumber }) => ({ segmentType, tatooNumber }));
};

const requirementRemarks = (traveller: Traveller) => {
  const remarks: AddRemarkInput[] = traveller.requirements.map((r) => ({
    freeFlowText: toRequirementCode(r.name) + r.value,
    category: "*",
    remarkType: "RM",
    associations: [
      {
        segmentType: SegmentType.PT,
        tatooNumber: traveller.tatooNumber.toString(),
      },
    ],
  }));
  return remarks;
};

export const createOrUpdateMarineRm = async (
  pnr: PNR,
  travellerTatoooNumber: number,
  ermInfo: ERMInfo
): Promise<PNR> => {
  const existingMarineRemark = findMarineRm(pnr, travellerTatoooNumber);
  if (existingMarineRemark) {
    pnr.addCancel({
      segmentType: existingMarineRemark.segmentType ?? "PT",
      tatooNumber: existingMarineRemark.tatooNumber,
    });
  }

  const addRemarkInput = toMarineRemark(ermInfo, travellerTatoooNumber);
  pnr.addRemark(addRemarkInput);
  return await firstValueFrom(PNRService.sendAddElements(pnr));
};
