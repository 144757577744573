import { MarineCode, RequirementName } from "./utils";

interface Status {
  Code: string;
  Message: string;
}

interface Employee {
  EmpCode: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  PhoneNumbers: string[];
  EmailAddresses: string[];
  FirmCode: string;
  BureauCode: string;
}

interface Email {
  Address: string;
  Info: boolean;
  Itinerary: boolean;
  Receipt: boolean;
  ETicketReceipt: boolean;
  Invoice: boolean;
}

interface Fqv {
  VendorCode: string;
  Type: string;
  Code: string;
}

interface CustRef {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface TravCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface DropDownItem {
  Value: string;
  Text: string;
}

interface ProCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
  DropDownList: DropDownItem[];
}

interface ProSubCode {
  Label: string;
  Description: string;
  Dynamic: boolean;
  Type: string;
}

interface CreditCard {
  Code: string;
  NoEncode: string;
  Display: string;
  ExpYear: number;
  ExpMonth: number;
  UseBusiness: boolean;
  UsePrivate: boolean;
  UseHotelGuarantee: boolean;
  IsLodgeCard: boolean;
  FreeText: string;
}

interface Cnf {
  CnfCode: string;
  Carriers: string[];
  Products: string[];
}

interface Profile {
  GdsCode: string;
  RecLoc: string;
  Name: string;
  OfficeID: string;
}

interface Ticketing {
  Offline: string;
  Online: string;
}

interface Refund {
  OfficeID: string;
  QueueNo: number;
  QueueCatNo: number;
}

interface HotelSI {
  SI: string;
  Countries: string[];
  Chains: string[];
  Hotels: string[];
}

//todo: depcode needs to be further defined
//todo: what can be null/undefined here?
export interface Lac {
  [key: string]: any;
  LacNo: number;
  LacName: string;
  LacNameSearch: string;
  FirmCode: string;
  Active: boolean;
  RacNo: number;
  NacNo: number;
  GacNo: number;
  CustomerLevel: string;
  CurrencyCode: string;
  LanguageCode: string;
  FormOfPayment: string;
  UseOfficeID: string;
  UseTac: boolean;
  UseProfile: boolean;
  UseAB: boolean;
  UseAM: boolean;
  PolicyCode: string;
  ConfigCode: string;
  MatrixServiceNo: number;
  IsCwtCustomer: boolean;
  HasMissedSavings: boolean;
  Employee: Employee;
  Phones: any[]; // Assuming this is an empty array or could be an array of some object type
  Emails: Email[];
  Fqvs: Fqv[];
  CustRef: CustRef | undefined;
  TravCode: TravCode | undefined;
  ProCode: ProCode | undefined;
  ProSubCode: ProSubCode | undefined;
  DepCode: object | undefined;
  CreditCards: CreditCard[];
  Cnfs: Cnf[];
  Profiles: Profile[];
  Savings: any[]; // Assuming this is an empty array or could be an array of some object type
  Ticketing: Ticketing;
  Refund: Refund;
  CarVoucher: boolean;
  HotelSIs: HotelSI[];
}

export interface GetLacResponse {
  Status: Status;
  Lac: Lac;
}

export enum StatusCode {
  Success = "Success",
  Failure = "Failure",
}

export interface ValidateRequirementResponse {
  Status: {
    Code: StatusCode;
    Message: string;
  };
}

export interface RequirementDetails {
  name: RequirementName;
  value: string | undefined;
  isValid: boolean | undefined;
  errorMessage: string | undefined;
}

interface CountryInfo {
  CitizenNameEn: string;
  NameEN: string;
}

export interface ERMInfo {
  regCountryCode: string | undefined;
  ermName: string | undefined;
  marineCode: MarineCode
}

export interface Flight {
  airline: string;
  tatooNumber: number;
}

export interface OsiElement {
  freeText: string;
}

export interface SsrElement {
  code: string;
  freeText: string;
}

export interface SkElement {
  code: string;
  freeText: string;
  //elementNo: number;
}

export interface Traveller {
  lacNo: number;
  tatooNumber: number; //more descriptive name needed?
  requirements: RequirementDetails[];
  fullName: string;
  agreementCodes: Fqv[];
  tickets: Ticket[];
  energyMarineInfo: ERMInfo | null;
}

export enum SegmentType {
  PT = "PT",
  ST = "ST",
  OT = "OT",
}

export interface Ticket {
  fareType: string;
  currency: string;
  amount: number;
  agreementCode: string | null; //is this on tst level or flight level?
  isAssociatedWithMarine: boolean;
}

export enum MarineRmCodes {
  VSL = "VSL",
  RIG = "RIG",
  WMF = "WMF",
}



export interface FullNode {
  miscellaneousRemarks: {
    remarks: {
      freetext: string;
      type: string;
    };
  };
  referenceForDataElement: {
    reference: {
      number: string;
      qualifier: string;
    };
  };
  elementManagementData: {
    reference: {
      number: string;
      qualifier: string;
    };
    lineNumber: string;
    segmentName: string;
  };
  extendedRemark: {
    structuredRemark: {
      freetext: string;
      type: string;
      category: string;
    };
  };
}
