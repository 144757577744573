import {
  AddRemarkInput,
  NameElement,
  PNR,
  PNRService,
  RMElement,
  SSRElementsType,
  TST,
} from "websdk-businessobjects";
import {
  ERMInfo,
  FullNode,
  Lac,
  OsiElement,
  RequirementDetails,
  SkElement,
  SsrElement as ssrElement,
  StatusCode,
  Ticket,
  Traveller,
} from "./models";
import { fetchLac, validateRequirements } from "./services";

export enum RequirementName {
  CustRef = "CustRef",
  ProCode = "ProCode",
  ProSubCode = "ProSubCode",
  DepCode = "DepCode",
  TravCode = "TravCode",
}

export const findRmElement = (
  pnr: PNR,
  key: RequirementName,
  travellerTatooNumber: number
) => {
  return pnr?.rmElements.find(
    (rm) =>
      rm.freeFlowText.startsWith(toRequirementCode(key)) &&
      rm.category === "*" &&
      rm.associations.some(
        (a) => a.tatooNumber === travellerTatooNumber.toString()
      )
  );
};

export const toRequirement = (
  pnr: PNR,
  key: RequirementName,
  travellerTatooNumber: number
): RequirementDetails => {
  const newLocal = findRmElement(pnr, key, travellerTatooNumber)!;
  return {
    value: newLocal?.freeFlowText.split("+")[2] ?? "",
    name: key,
    isValid: false,
    errorMessage: undefined,
  };
};

export const toRequirementCode = (requirement: RequirementName): string => {
  switch (requirement) {
    case RequirementName.CustRef:
      return "BH+R+";
    case RequirementName.ProCode:
      return "XI+PRO+";
    case RequirementName.ProSubCode:
      return "XI+SUB+";
    case RequirementName.DepCode:
      return "XI+DNO+";
    case RequirementName.TravCode:
      return "XI+ENO+";
    default:
      throw new Error("Invalid requirement");
  }
};

export const toRequirements = (
  lac: Partial<Lac>,
  pnr: PNR,
  travallerTatooNumber: number
): RequirementDetails[] => {
  return Object.keys(RequirementName)
    .filter((key) => lac[key] !== undefined)
    .map((key) =>
      toRequirement(pnr, key as RequirementName, travallerTatooNumber)
    );
};

export const toLacNoNameElement = (pnr: PNR, nameElement: NameElement) => {
  const key = "*ACC";
  const elem = pnr.rmElements.find((rm) => {
    if (!rm.fullNode) return false;
    return (
      (
        rm.fullNode as FullNode
      ).miscellaneousRemarks?.remarks?.freetext?.startsWith(key) &&
      (rm?.associations?.some(
        (e) => e.tatooNumber === nameElement.tatooNumber
      ) ??
        true)
    );
  })!;

  const newLocal = (elem.fullNode as FullNode).miscellaneousRemarks?.remarks
    ?.freetext;
  return newLocal.slice(4, newLocal.length);
};

const findErmAssociation = (pnr: PNR, tatooNumber: number) => {
  return pnr.rmElements.some((rm) => {
    if (!rm.fullNode) return false;
    return (
      (rm.fullNode as FullNode).miscellaneousRemarks?.remarks?.freetext ===
      `SEMN`
    );
  });
};

const findOsiElements = (
  pnr: PNR,
  travellerTatooNumber: number
): OsiElement[] => {
  const osiElements = pnr.osiElements.filter(
    (osi) => osi.tatooNumber === travellerTatooNumber.toString()
  );
  return osiElements.map((osi) => ({
    freeText: osi.tatooNumber,
  }));
};

const findSsrCode = (pnr: PNR, ssr: SSRElementsType) => {
  return "ssrcode"; //todo: not implemented
};

const findSsrElements = (pnr: PNR, tatooNumber: number): ssrElement[] => {
  return pnr.ssrElements
    .filter((ssr) =>
      ssr.associations.some((a) => a.tatooNumber === tatooNumber.toString())
    )
    .map((ssr) => {
      return {
        code: findSsrCode(pnr, ssr),
        freeText: ssr.freeFlowText,
      };
    }); //todo: need to look back onto this
};

const findSkElements = (pnr: PNR, tatooNumber: number): SkElement[] => {
  return pnr.ssrElements
    .filter((ssr) =>
      ssr.associations.some((a) => a.tatooNumber === tatooNumber.toString())
    )
    .map((a) => ({
      code: a.freeFlowText,
      freeText: a.freeFlowText,
    })); //todo: need to look back onto this
};

export const initTravellers = async (pnr: PNR, tst: TST) => {
  const promises = pnr.nameElements.map(async (nameElement) => {
    const lacNo = parseInt(toLacNoNameElement(pnr, nameElement));
    const lac = await fetchLac(lacNo);
    if (lac.Status.Code === StatusCode.Failure)
      throw new Error("Failed to fetch LAC");
    const requirements = toRequirements(
      lac.Lac,
      pnr,
      parseInt(nameElement.tatooNumber)
    );
    const vr = await validateRequirements(lacNo, requirements);

    const ermInfo = extractErmInfo(pnr, parseInt(nameElement.tatooNumber));

    const agreementCode = extractAgreementCode(pnr, nameElement.tatooNumber);

    const tickets: Ticket[] = extractTickets(
      tst,
      nameElement,
      agreementCode,
      ermInfo
    );

    const traveller: Traveller = {
      lacNo: lacNo,
      tatooNumber: parseInt(nameElement.tatooNumber),
      requirements: vr,
      fullName: nameElement.firstName + " " + nameElement.lastName,
      agreementCodes: lac?.Lac?.Fqvs ?? [],
      tickets: tickets,
      energyMarineInfo: ermInfo,
    };
    return traveller;
  });

  return await Promise.all(promises);
};

export enum MarineCode {
  VSL = "VSL",
  RIG = "RIG",
  WMF = "WMF",
}

enum MarineFields {
  REG = "REG",
  RIG = "RIG",
  WMF = "WMF",
  VSL = "VSL",
  DIR = "DIR",
  COMP = "COMP",
  PHONE = "PHONE",
}

const extractMarineCode = (marineRm: RMElement) => {
  if (marineRm.freeFlowText.startsWith(MarineCode.RIG.toString()))
    return MarineCode.RIG;
  if (marineRm.freeFlowText.startsWith(MarineCode.VSL.toString()))
    return MarineCode.VSL;
  if (marineRm.freeFlowText.startsWith(MarineCode.WMF.toString()))
    return MarineCode.WMF;
  throw new Error("Invalid marine code");
};

const extractTickets = (
  tst: TST,
  nameElement: NameElement,
  agreementCode: string,
  ermInfo: ERMInfo | null
): Ticket[] => {
  return tst.list
    .filter(
      (te) =>
        te.associations.some(
          (a) =>
            a.tatooNumber === nameElement.tatooNumber &&
            a.segmentType === nameElement.segmentType
        ) || !!te.associations
    )
    .flatMap((te) => {
      return te.grandTotalFareElements.map((fe) => {
        return {
          fareType: fe.fareType,
          currency: fe.currency,
          amount: parseFloat(fe.amount),
          agreementCode: agreementCode,
          isAssociatedWithMarine: !!ermInfo,
        };
      });
    });
};

export const extractErmInfo = (pnr: PNR, travellerTatooNumber: number) => {
  const marineRm = findMarineRm(pnr, travellerTatooNumber);

  if (!marineRm) return null;

  const marineRmSplit = marineRm.freeFlowText.split("/");

  const marineInfo: MarineInfo = marineRmSplit.reduce((acc, curr) => {
    const [key, value] = curr.split(":");
    return { ...acc, [key as keyof MarineInfo]: value };
  }, {}) as MarineInfo;

  const ermInfo: ERMInfo = {
    regCountryCode: marineInfo["REG"] ?? "NO",
    ermName:
      (marineRm.freeFlowText.startsWith(MarineCode.VSL.toString()) &&
        marineInfo[MarineFields.VSL.toString()]) ||
      (marineRm.freeFlowText.startsWith(MarineCode.RIG.toString()) &&
        marineInfo[MarineFields.RIG.toString()]) ||
      (marineRm.freeFlowText.startsWith(MarineCode.WMF.toString()) &&
        marineInfo[MarineFields.WMF.toString()]) ||
      "",
    marineCode: extractMarineCode(marineRm),
  };

  return ermInfo;
};

interface MarineInfo {
  //todo: what can be undefined here?
  [key: string]: string | undefined;
  REG: string | undefined;
  RIG: string | undefined;
  WMF: string | undefined;
  VSL: string | undefined;
  DIR: string | undefined;
  COMP: string | undefined;
  PHONE: string | undefined;
}

export const findMarineRm = (pnr: PNR, travellerTatooNumber: number) => {
  const marineRm = pnr.rmElements.find((rm) => {
    const isMarineRm =
      rm.freeFlowText.startsWith(MarineCode.RIG.toString()) ||
      rm.freeFlowText.startsWith(MarineCode.VSL.toString()) ||
      rm.freeFlowText.startsWith(MarineCode.WMF.toString());

    const isAssociatedWithTraveller =
      rm.associations.some(
        (a) => a.tatooNumber === travellerTatooNumber.toString()
      ) || rm.associations.length === 0;

    return isMarineRm && isAssociatedWithTraveller;
  });

  return marineRm;
};

export const toMarineRemark = (ermInfo: ERMInfo, travellerTatoooNumber: number) => {
  const marineCode = ermInfo.marineCode;
  const freeFlowText = `${marineCode.toString()}:${ermInfo.ermName}/${MarineFields.REG
    }:${ermInfo.regCountryCode}`;
  const addRemarkInput = {
    associations: [
      { segmentType: "PT", tatooNumber: travellerTatoooNumber.toString() },
    ],
    category: "*",
    freeFlowText,
  };
  return addRemarkInput;
};

export const extractAgreementCode = (pnr: PNR, tatooNumber: string) => {
  return "";
};


